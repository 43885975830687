import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "table-layouts"
    }}>{`Table Layouts`}</h1>
    <p>{`Support mobile first design standards, DO NOT use html tables tags; use flex blocks instead.`}</p>
    <p>{`General Styling Rules:`}</p>
    <p>{`No centering, left justified for left column`}</p>
    <p>{`Right column - right justify, have commas line up with each other`}</p>
    <p>{`Horizontal borders, full borders, maybe no default - depends on overall design`}</p>
    <p>{`Reverse out the heading`}</p>
    <p>{`Mobile design first is essential - stacking of columns in this case is useless`}</p>
    <p>{`(provide some examples)`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p>{`NO `}<inlineCode parentName="p">{`<td></td>`}</inlineCode></p>
    <p>{`YES `}<inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      